import React from 'react'
import About from './About';
import Footer from './Footer';
import Form from './Form';
import Header from './Header';
import Hero from './Hero';
import MissionStatement from './MissionStatement';
import Services from './Services';
import WhatsAppButton from './WhatsAppButton';

function LandingPage() {
  return (
    <>
      <Header/>
      <Hero/>
      
      <About/>
      <MissionStatement/>
      <Services />
      <Form/>
      <Footer/> 
      <WhatsAppButton/>
    </>
  )
}

export default LandingPage