import React from 'react'
import logo from '../img/logo.png'

export default function Header() {
  return (
    <div className='md:sticky md:top-0 md:bg-white border-b-2 border-[#539BDC] z-50'>
    <header className='flex flex-wrap justify-center md:justify-between container mx-auto items-center'>
      <a href="/">

        <img src={logo} alt="logo de yecyoc" className='h-16'/>
      </a>
        <nav className=''>
          <ul className='flex flex-wrap justify-around md:space-x-12 mb-6 sm:mb-0'>
            <li className='mx-4'><a href="/">Inicio</a></li>
            <li className='mx-4 ml-10 sm:ml-4'><a href="#about">Nosotros</a></li>
            <li className='mx-4'><a href="#services">Servicios</a></li>
            <li className='mx-4 mt-4 sm:mt-0'><a className='bg-[#539BDC] px-4 py-2 rounded-xl hover:text-white' href="#form"> <strong>Contactanos</strong></a></li>
          </ul>
        </nav>
    </header>
    </div>
  )
}
