import React from 'react'

export default function Services() {
  return (
    <section id='services' className='pt-24 px-6 relative -top-12'>
      <div className='container mx-auto text-center space-y-8'>
        <h3 className='text-2xl mb-4'><strong>Nuestros servicios</strong></h3>
        <h3 className='inline text-lg'>Te asesoramos para hacer crecer y expandir tu actividad</h3><p className='text-lg inline'>, acompañando el crecimiento de tus ventas y rentabilidad. <span className="hidden md:inline">Trabajamos en la Gestión Activa que permite concretar tus ventas, en la Gestión Postventa y la Fidelización. Comprendemos la importancia de lograr una adecuada Gestión Logística para bajar costos y satisfacer las necesidades del cliente.</span></p>
        
        <div className='flex flex-col space-y-8 md:space-y-0 justify-around md:flex-row'>

          <div className='flex flex-col pt-8 pb-4 rounded-lg shadow-xl space-y-6 bg-[#C9DFF4] items-start md:w-5/12'>
            <h4 className='w-10/12 mx-auto text-start'><strong className='bg-[#539BDC] py-2 px-3 text-center rounded-lg text-lg'>Onboarding</strong></h4>
            <p className='w-10/12 text-left mx-auto text-lg'>Si no estás operando en el ecosistema de Mercado Libre o bien si alguna vez operaste o utilizaste alguno de sus sistemas parte, como Mercado Pago o incluso el Marketplace de Mercado Libre para pequeñas operaciones, te ofrecemos un servicio de Onboarding, de lanzamiento o re-lanzamiento de tu actividad en la plataforma de Mercado Libre para tus productos y/o servicios.</p>
          </div>

          <div className='flex flex-col pt-8 pb-4 rounded-lg shadow-xl space-y-6 bg-[#C9DFF4] items-start md:w-5/12'>
            <h4 className='w-10/12 mx-auto text-start'><strong className='bg-[#539BDC] py-2 px-3 text-center rounded-lg text-lg'>Mejora continua</strong></h4>
            <p className='w-10/12 text-left mx-auto text-lg'>Si ya estás operado en el ecosistema de Mercado Libre y dominás al menos en parte, sus mejores prácticas y técnicas, te ofrecemos una inmersión exhaustiva en todas aquellas técnicas que te permitirán aprovechar al máximo tu experiencia, incrementar la rentabilidad de tu negocio actual e incluso expandir tu alcance en nuevas verticales o ramas de industria.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
