import React from 'react'
import certificacion from '../img/certification.svg'
import heroImg from '../img/hero-img.svg'
import HeroLowerBanner from './HeroLowerBanner'

export default function Hero() {
  return (
    <main id='home' className='bg-[#C9DFF4] h-[32rem] sm:h-auto'>
      <div className='container flex mx-auto pt-8 sm:pt-20 w-5/6 md:w-auto'>
        <div className='min-h-[324px] sm:min-h-auto text-center md:text-left md:w-1/2 flex flex-col justify-center md:ml-12 space-y-6'>
          <h1 className='text-2xl md:text-4xl lg:text-5xl'><strong>Somos consultores certificados en MercadoLibre</strong></h1>
          <h2 className='text-lg'>Ahora sos vos el que diseña, fabrica y vende. Re-creamos el negocio a tu lado cada día. Transformamos tus ideas en productos y servicios rentables.</h2>
          <div className='flex justify-center md:justify-start'>
            <a href="#form" className='h-10 bg-white text-center py-2 px-2 rounded-lg mr-4'><strong>Contactanos</strong></a>
            <img src={certificacion} alt="Consultora certificada por MercadoLibre" className='h-10'/>
          </div>
        </div>
        <div className='w-1/2 hidden md:inline-block'>
          <img src={heroImg} alt="equipo de consultoria" className='w-full h-full my-auto' />
        </div>
        
      </div>
      <HeroLowerBanner/>


    </main>
  )
}
