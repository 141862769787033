import React from 'react'
import logo from '../img/logo.png'

export default function MissionStatement() {
  return (
    <section className='bg-[#dceaf8] pt-4'>
      <div className='container mx-auto flex justify-center flex-col md:flex-row pb-16 sm:pb-10'>
        <img src={logo} alt="brand logo" className='w-5/6 md:w-1/2 h-auto mx-auto' />
        <div className='w-5/6 text-xl my-auto text-center mx-auto md:mx-0'>
          <p className='inline'><span translate="no"><strong className='text-[#2873B5] text-2xl'>Y</strong>ears and <strong className='text-[#E76003] text-2xl'>Y</strong>ears</span></p><h3 className='inline'> generando negocios que cambian y se adaptan</h3>
          <p><span translate="no"><strong className='text-[#DF0209] text-2xl'>E</strong>njoying what we do,</span> trabajamos en lo que nos gusta</p>
          <p><span translate="no"><strong className='text-[#F7B63B] text-2xl'>O</strong>pportunity</span> para el desarrollo y crecimiento sustentables</p>
          <p className='inline'><span translate="no"><strong className='text-[#99BE11] text-2xl'>C</strong>ustomer first, <strong className='text-[#009951] text-2xl'>C</strong>ustomer always, </span>  </p><h2 className='inline'>enfocados en las necesidades del cliente</h2>
        </div>
      </div>
    </section>
  )
}
