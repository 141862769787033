import React from 'react'
import about from '../img/about.svg'

export default function About() {
  return (
    <>
    <section id='about' className='mt-24 sm:mt-0 pt-32 sm:pb-12'>
    <h3 className='hidden sm:block text-2xl text-center' ><strong>Te ofrecemos una excelente calidad de servicio</strong></h3>
    <div className='container mx-auto flex align-top pt-12 items-center'>
      <div className='md:w-1/2'>
        <img src={about} alt="nuestro equipo de trabajo" className=' md:h-96 xl:h-80 hidden md:inline-block'/>
      </div>
      <div className='md:w-1/2 px-8 flex flex-col space-y-6 mb-12 lg:pl-32 '>
        <h3 className='sm:hidden text-2xl text-center' ><strong>Te ofrecemos una excelente calidad de servicio</strong></h3>
        <img src={about} alt="nuestro equipo de trabajo" className=' md:h-96 md:hidden'/>
        <p className='text-lg'>Fuimos entrenados con las técnicas necesarias para gestionar tus productos y servicios, con buenas prácticas, aprovechando al máximo las ventajas del algoritmo de venta.</p>
        <p className='text-lg' >Somos profesionales Certificados por Mercado Libre con experiencia en holdings y PyMes, en Marketing y Ventas, Producción, Compras, Logística, Calidad, entre otros. </p>
      </div>
    </div>

    </section>
    </>
  )
}
