import React from 'react'
import whatsapp from '../img/whatsapp.svg'

export default function WhatsAppButton() {
  return (
    <div className='fixed bottom-0 left-1'>
<a
  target="_blank"
  href="https://api.whatsapp.com/send?phone=5491135237000&amp;text=¡Hola!+Quisiera+contactarlos+para+hacer+una+consulta.+Hi%21+I+would+like+to+contact+you+to+make+an+inquiry."
  id="whatsapp-button"
  className="fixed bottom-12 left-12 w-[10vh] z-100 "
>
              <img className='h-14 m-4 ' src={whatsapp} alt="Whatsapp button" />
        </a>
    </div>
  )
}
